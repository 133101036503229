import React, { useRef } from "react";
import { createRoot } from 'react-dom/client';
import ErrorBox from "../common/ErrorBox";
import TableComponent from "./TableComponent";
import { useState, useEffect } from "react";
import { App, Modal } from 'antd';
import { EditableContextProvider } from "../common/EditableContextProvider";
import useProducts from "./useProducts";
import FormComponent from "./FormComponent";

export default function Main() {
    const {
        editItem, fillItems, items, selectItem,
        newItem, saveItem, destroyItem, loading,
        tableParams, clone, fillBranchs, branchs } = useProducts();
    const [formVisible, setFormVisible] = useState(false);
    const { modal, notification } = App.useApp();

    const container_div = document.querySelector("#products-main");
    const handle_refresh_event = useRef(() => fillItems());

    useEffect(() => {
        window.refresh_event = new Event('refresh_event');
        container_div.addEventListener('refresh_event', handle_refresh_event.current)

        fillItems();
        fillBranchs();

        return () => {
            container_div.removeEventListener('refresh_event', handle_refresh_event.current);
        }
    }, []);

    const handleTableChange = (pagination, filters, sorter, extra) => {
        let params = {
            ...tableParams,
            pagination,
            filters,
            sorter,
        };
        fillItems(params);
    }
    const handleFormAction = async (item) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (item === editItem) { notification.info({ description: "No hay cambios." }); resolve(); return; }
                await saveItem(item);
                notification.success({ description: "¡Guardado correcto!" });
                resolve();
            } catch (e) {
                if (e.code === "SERVER_ERROR") {
                    notification.error({ description: e.message });
                }
                reject(e);
            }
        });
    }
    const handleDeleteItem = (id) => {
        modal.confirm({
            content: `¿Eliminar ítem (ID: ${id}) ?`,
            onOk: async () => {
                try {
                    await destroyItem(id);
                    notification.info({ message: "¡Item eliminado!" });
                } catch ({ response }) {
                    notification.error({ message: "Ocurrió un error al eliminar el ítem." });
                    console.log(response.data);
                }
            }
        });
    }
    const addNewItem = () => { newItem(); showForm(); }
    const setUpdateItem = (item) => { selectItem(item); showForm(); }
    const showForm = () => { setFormVisible(true); }
    const hideForm = () => { setFormVisible(false); }

    return (
        <div>
            <div className='my-1 py-1 row'>
                <div className="col-md-auto">
                    <button className="btn btn-sm btn-light border"
                        onClick={() => { addNewItem(); }}
                    >
                        <i className="fa-solid fa-plus me-1"></i>
                        Agregar
                    </button>
                </div>
                <div className="col-md">
                </div>
                <div className="col-md-auto">
                    <button className="btn btn-sm btn-light border" type="button"
                        onClick={() => { livewire.emitTo('products.table', 'get-csv') }}>
                        <span className="fas fa-table me-1"></span>
                        Descargar CSV
                    </button>
                </div>
                <div className="col-md-auto">
                    <button className="btn btn-sm btn-light border" type="button"
                        onClick={() => { livewire.emit('show_upload_show') }}
                    >
                        <span className="fas fa-upload me-1"></span>
                        Cargar CSV
                    </button>
                </div>

            </div>
            <TableComponent
                items={items}
                isLoading={loading}
                tableParams={tableParams}
                onTableChange={handleTableChange}
                updateItem={setUpdateItem}
                deleteItem={handleDeleteItem}
                clone={clone}
            />

            <Modal title="Formulario"
                open={formVisible}
                onCancel={hideForm}
                destroyOnClose={true}
                footer={null}
                width={900}
            >
                <EditableContextProvider data={{
                    editItem: editItem,
                    onFormAction: handleFormAction,
                    branchs
                }}
                >
                    <FormComponent />
                </EditableContextProvider>
            </Modal>
        </div>
    );
}

let main = document.getElementById('products-main');
if (main) {
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <App>
                    <Main />
                </App>
            </ErrorBox>
        </React.StrictMode>
    );
}
