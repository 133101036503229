import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import { Select, Spin } from 'antd';
import FormError from "../common/FormError";
import { EditableContext } from "../common/EditableContextProvider";

const FormComponent = ({ errors }) => {
    const { editItem, onFormAction, branchs } = useContext(EditableContext);
    const [initialValues, setInitialvalues] = useState(editItem);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInitialvalues({
            ...editItem
        });
    }, [editItem]);

    const handleSubmit = async (values, formik) => {
        try {
            setLoading(true);
            await onFormAction(values);
            setInitialvalues(values);
        } catch (e) {
            if (e.code === "REQ_FAIL") {
                if (e.errors.email) { formik.setFieldError("email", e.errors.email[0]); }
            }
        }
        finally { setLoading(false); }
    }

    const validateSchema = Yup.object({
        name: Yup.string()
            .min(3, 'El nombre debe tener al menos 3 letras.')
            .max(150, 'El texto es demasiado largo')
            .required('El campo es obligatorio.'),
        price: Yup.number()
            .required('El campo es obligatorio.'),
        branchs: Yup.array()
            .min(1, "Seleccione al menos 1 item.")
            .required('El campo es obligatorio.'),
    });

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validateSchema}
                enableReinitialize={true}
            >
                {
                    (formik) => (
                        <Form disabled={loading}>
                            {/* <div className="py-1">
                                <span>ID: </span>
                                <span className="fw-bold ms-1">{sprintf('%04d', editItem.id)}</span>
                            </div> */}
                            <div className="mb-2 row">
                            <div className="col-md">
                                    <label htmlFor="code" className="form-label">
                                        Código Producto
                                    </label>
                                    <Field id="code" name="code" value={formik.values.code ?? ''} type="text" className="form-control form-control-sm" />
                                    <ErrorMessage name="code" component={FormError} />
                                </div>
                                <div className="col-md">
                                    <label htmlFor="name" className="form-label">
                                        Nombre
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Field id="name" name="name" type="text" className="form-control form-control-sm" />
                                    <ErrorMessage name="name" component={FormError} />
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="description" className="form-label">
                                    Descripción
                                    <span className="text-danger">(*)</span>
                                </label>
                                <Field id="description" name="description" type="text" className="form-control form-control-sm" />
                                <ErrorMessage name="description" component={FormError} />
                            </div>
                            <div className="mb-2 row">
                                <div className="col-md">
                                    <label htmlFor="type" className="form-label">
                                        Tipo
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Select
                                        className="w-100"
                                        options={[
                                            { value: "product", label: "PRODUCTO" },
                                            { value: "service", label: "SERVICIO" },
                                        ]}
                                        value={formik.values.type}
                                        onChange={(value) => formik.setFieldValue('type', value)}
                                        onBlur={() => formik.setFieldTouched('type')}
                                    ></Select>
                                    <ErrorMessage name="type" component={FormError} />
                                </div>
                                <div className="col-md">
                                    <label htmlFor="price" className="form-label">
                                        PRECIO UNITARIO EN BS.
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Field id="price" name="price" type="number" step="any" className="form-control form-control-sm" />
                                    <ErrorMessage name="price" component={FormError} />
                                </div>
                                <div className="col-md">
                                    <label htmlFor="branchs" className="form-label">
                                        SUCURSAL
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Select
                                        className="w-100"
                                        name="branchs"
                                        options={
                                            [
                                                ...branchs.map(b => ({ value: b.id, label: b.name }))
                                            ]
                                        }
                                        value={formik.values.branchs}
                                        onChange={(value) => formik.setFieldValue('branchs', value)}
                                        onBlur={() => formik.setFieldTouched('branchs')}
                                        mode="multiple"
                                    ></Select>
                                    <ErrorMessage name="branchs" component={FormError} />
                                </div>

                            </div>

                            <div className="mb-2">
                                <label htmlFor="status" className="form-label" >
                                    <Field name="status" id="status" type="checkbox" className="form-checkbox me-2" />
                                    Activo
                                </label>
                                <ErrorMessage name="status" component={FormError} />
                            </div>

                            {
                                (formik.values.type === 'product' && formik.values.id === 0)? (
                                    <div className="mb-2 p-2" style={{ background: 'lightgray' }}>
                                        <label htmlFor="create_suply" className="form-label" >
                                            <Field name="create_suply" id="status" type="checkbox" className="form-checkbox me-2" />
                                            CREAR INSUMO CON EL MISMO NOMBRE
                                        </label>
                                        <ErrorMessage name="create_suply" component={FormError} />
                                    </div>
                                ) : ''
                            }

                            <div className="mb-2 d-flex justify-content-between">
                                <div>
                                    <span className="text-danger">(*) </span>
                                    <u>Campo obligatorio.</u>
                                </div>
                                <button type="submit" className="btn btn-sm btn-light border border-secondary px-4">
                                    {
                                        (loading) ? (
                                            <Spin size="small" />
                                        ) : "Guardar"
                                    }
                                </button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
}

export default FormComponent;
