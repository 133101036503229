import { connector } from "../common/connector";

export const defaultItem = {
    id: 0,
    name: "",
    code: "",
    description: "",
    type: "product",
    status: true,
    price: 0,
    create_suply: false,
    branchs: []
}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FILL':
            return action.payload;

        case 'ADD':
            return [...state, action.payload];

        case 'UPDATE':
            return state.map(it => {
                if(it.id === action.payload.id) { return action.payload; }
                else { return it; }
            });

        case 'REMOVE':
            return state.filter(it => it.id != action.payload);

        default: return state;
    }
}

export const getItems = (params) => {
    return connector.get('api/productos', { params: params });
}

export const getBranchs = () => {
    return connector.get('api/productos/branchs');
}

export const createItem = (data) => {
    return connector.post('api/productos', data);
}

export const updateItem = (data) => {
    return connector.put(`api/productos/${data.id}`, data)
}

export const deleteItem = (id) => {
    return connector.delete(`api/productos/${id}`);
}

export const cloneItem = (id) => {
    return connector.get(`api/productos/${id}/clone`);
}
